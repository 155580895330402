import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthenticatedUser } from '@tremaze/shared/core/auth-v2/types';
import { catchError, map, mapTo, switchMap, take } from 'rxjs/operators';
import { TremazeHttpResponse } from '@tremaze/shared/util-http/types';
import { User } from '@tremaze/shared/feature/user/types';
import { jsonHttpHeaders } from '@tremaze/shared/util-http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { TremazeDate } from '@tremaze/shared/util-date';
import { Gender } from '@tremaze/shared/feature/gender/types';
import { Address } from '@tremaze/shared/models';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';

@Injectable({ providedIn: 'root' })
export abstract class ProfileDataSource {
  abstract getProfileData(): Observable<AuthenticatedUser>;

  abstract setProfileData(
    userProfileData: UserProfileData
  ): Observable<User> | null;

  abstract deleteAccount(): Observable<boolean>;

  abstract setPassword(
    newPassword: string,
    currentPassword: string
  ): Observable<boolean>;
}

interface SetProfileDataPayload {
  address: Address;
  avatarId: string;
  birth: TremazeDate;
  email: string;
  firstName: string;
  lastName: string;
  genderId: string;
  mobile: string;
  other: string;
  phone: string;
  username: string;
}

@Injectable({ providedIn: 'root' })
export class RemoteProfileDataSource implements ProfileDataSource {
  constructor(
    private http: HttpClient,
    private js: JsonSerializer,
    private authService: AuthV2Service
  ) {}

  setPassword(
    newPassword: string,
    currentPassword: string
  ): Observable<boolean> {
    return this.http
      .put<TremazeHttpResponse<unknown>>('/app/users/me/password', null, {
        params: { currentPassword, newPassword, skipTenantId: 'true' },
      })
      .pipe(
        map((r) => {
          if (r.status === 'SUCCESS') {
            return true;
          }
          throw false;
        })
      );
  }

  getProfileData(): Observable<AuthenticatedUser> {
    return this.authService.authenticatedUser$.pipe(take(1));
  }

  setProfileData(userProfileData: UserProfileData): Observable<User> | null {
    console.log('setProfileData', userProfileData);
    return this.getProfileData().pipe(
      switchMap((profileData) => {
        const payload: SetProfileDataPayload = {
          address: userProfileData.address,
          email: userProfileData.email ?? profileData.email,
          genderId: userProfileData.gender?.id,
          birth: userProfileData.birth?.setOverrideUTC(true),
          firstName: userProfileData.firstname,
          lastName: userProfileData.lastname,
          other: undefined,
          mobile: userProfileData.mobile,
          username: profileData.username,
          phone: userProfileData.phone,
          avatarId: profileData.profileImage?.id,
        };
        return this.http
          .put<TremazeHttpResponse<User>>(`/cc/users/me`, payload, {
            headers: jsonHttpHeaders,
            params: { skipTenantId: 'true' },
          })
          .pipe(
            map((r) => {
              if (r?.status === 'SUCCESS') {
                return User.deserialize(r.object);
              }
              throw r?.status;
            })
          );
      })
    );
  }

  deleteAccount(): Observable<boolean> {
    return this.http.delete(`/app/user/deleteOwnUser`).pipe(
      mapTo(true),
      catchError(() => of(false))
    );
  }
}

export interface UserProfileData {
  firstname?: string;
  lastname?: string;
  birth?: TremazeDate;
  gender?: Gender;
  phone?: string;
  mobile?: string;
  address?: Address;
  email?: string;
  password?: string;
}
